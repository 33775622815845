import images from "./images";

// skills
const skills = [
    {
        title: ".NET",
        value: "80%"
    },
    {
        title: "JAVA",
        value: "75%"
    },
    {
        title: "DATA SCIENCE",
        value: "90%"
    },
    {
        title: "DATA ANALYST",
        value: "85%"
    },
];

// stats
const stats = [
    {
        img: `${images.icon_briefcase}`,
        value:  548,
        title: "projects completed"
    },
    {
        img: `${images.icon_clock}`,
        value: 1465,
        title: "working hours"
    },
    {
        img: `${images.icon_star_white}`,
        value: 612,
        title: "positive feedbacks"
    },
    {
        img: `${images.icon_heart}`,
        value: 735,
        title: "happy clients"
    },
];

// works
const works = [
  {
    img: `${images.work_1}`
  },
  {
    img: `${images.work_2}`
  },
  {
    img: `${images.work_3}`
  },
  {
    img: `${images.work_4}`
  },
  {
    img: `${images.work_5}`
  },
  {
    img: `${images.work_6}`
  },
  {
    img: `${images.work_7}`
  },
  {
    img: `${images.work_8}`
  }
]

// services
const services = [
    {
        img: `${images.icon_diamond}`,
        title: "DATA SCIENCE",
        text: 'We provide end-to-end services.'
    },
    {
        img: `${images.icon_archer}`,
        title: "DATA ANALYSIS",
        text: 'We provide end-to-end services.'
    },
    {
        img: `${images.icon_phone}`,
        title: "MACHINE LEARNING",
        text: 'We provide end-to-end services.'
    },
    {
        img: `${images.icon_console}`,
        title: "DEEP LEARNING",
        text: 'We provide end-to-end services.'
    },
    {
        img: `${images.icon_plane}`,
        title: "ARTIFICIAL INTELLIGENCE",
        text: 'We provide end-to-end services.'
    },
    {
        img: `${images.icon_star}`,
        title: "MY SQL",
        text: 'We provide end-to-end services.'
    },
    {
        img: `${images.icon_fantasy}`,
        title: "WEB DEVELOPMENT",
        text: 'We provide end-to-end services.'
    },
    {
        img: `${images.icon_paint}`,
        title: "APPLICATION DEVELOPMENT",
        text: 'We provide end-to-end services.'
    }
];

// testimonials
const testimonials = [
    {
        name: "Jeetendra Nath Mishra, Director",
        text: "The staff and support are second to none. They are polished, proficient, accessible and patient.",
    },
    {
        name: "Shubham Kumar",
        text: "Outstanding job and exceeded all expectations. It was a pleasure to work with them on a sizable first project and am looking forward to start the next one asap."
    },
    {
        name: "Kundan Krishna",
        text: "We completed our product website with Global FirstWave and are so excited to have it live!! It is so professionally done and eye appealing, not to mention how great it looks and works. We love how easy it is to make changes ourselves, yet know we have the support when/if we need it."
    },
    {
        name: "Ayush Mishra",
        text: "When you choose Global FirstWave you get a wonderful, professional team with innovative ideas, awesome customer service, and exactly what you're looking for. It took the ideas that we had and put them perfecly on the web."
    }
];

export default {skills, stats, works, services, testimonials};
