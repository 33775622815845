import './App.css';
import Header from "./components/Header/Header";
import Home from './components/Home/Home';
import Skills from "./components/Skills/Skills";
import Project from "./components/Project/Project";
import WorkProcess from './components/WorkProcess/WorkProcess';
// import Services from "./components/Services/Services";
import Careers from "./components/Careers/Careers";
import Footer from './components/Footer/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/skills' element={<Skills />} />
        <Route path='/careers' element={<Careers />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    // <div className="holder">
    //   <Header />
    //   <Skills />
    //   <Project />
    //   <WorkProcess />
    //   <Careers />
    //   <Footer />
    // </div >
  );
}

export default App;