import React from "react";
import "./WorkProcess.css";

const WorkProcess = () => {
    return (
        <section id="careers">
            <div className="workprocess section__padding">
                <div className="container">
                    <div className="workprocess__content text__center">
                        <h2 className="section__title text__cap">Our work process</h2>
                        <p className="para__text">"Our company's work process is defined by meticulous planning, agile execution, and client-centric focus. From initial ideation to final delivery, we prioritize efficiency, transparency, and quality. Our dedicated team ensures seamless collaboration, adapting swiftly to evolving needs, to deliver exceptional results on time and within scope.".</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WorkProcess;