import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import "./Careers.css";

const Careers = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceId = 'service_iy96g5h';
        const templateId = 'template_1j9ogu5';
        const publicKey = 'njFDo8mcs9tb7ZWIJ';


        //create a new object that contains dynamic template params
        const templateParams = {
            from_name: name,
            from_email: email,
            from_title: title,
            to_name: 'Global FirstWave',
            message: message,
        };

        //Send the email using emailjs
        // emailjs.send(serviceId, templateId, templateParams, publicKey)
        // .then((response) => {
        //     console.log('Email sent successfully!', response);
        //     setName('');
        //     setEmail('');
        //     setTitle('');
        //     setMessage('');
        // })
        // .catch((error) => {
        //     console.error('Error sending email:', error);
        // })
    }

    return (
        <section>
            <div className="contact section__padding bg__whitesmoke">
                <div className="container">
                    <h2 className="section__title text__center">Stay in Touch!</h2>
                    <p className="para__text text__center">Let us know what you're looking for in an Company. We'll take a look and see if this could be the start of something beautiful.</p>

                    <div className="contact__content">
                        <form onSubmit={handleSubmit}>
                            <div className="form__elem form__elem--2">
                                <input type="text" className="form__control" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                <input type="text" className="form__control" placeholder="Your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form__elem">
                                <input type="text" className="form__control" placeholder="Your Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className="form__elem">
                                <textarea className="form__control" placeholder="Message Length 1 - 1000 Characters" rows="4" cols="50" minlength="1" maxlength="1000" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                            <button type="submit" className="form__submit--btn btn btn__blue">send message</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Careers;