import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer section__padding bg__dark">
            <div className="container">
                <div className="footer__content grid text__light text__center">
                    <div className="footer__content--item">
                        {/* <a href="#" className="footer__navlink">Global FirstWave.</a> */}
                        <NavLink to="/" className="footer__navlink">Global FirstWave.</NavLink>
                        <p className="para__text">&copy; 2024 Global FirstWave. All rights reserved.</p>
                    </div>

                    <div className="footer__content--item">
                        <a href="mailto:someone@gmail.com">careers@globalfirstwave.com</a><br />
                        {/* <span>+91 7273879716</span> */}
                    </div>

                    <div className="footer__content--item">
                        <NavLink to="/skills"><h3 className="footer__title">Projects</h3></NavLink>
                        <ul className="footer__links">
                            <li><a href="#AboutUs">About</a></li>
                            <li><a href="#Services">Services</a></li>
                            {/* <li><a href="#">Career</a></li> */}
                            <NavLink to="/careers">Careers</NavLink>
                        </ul>
                    </div>

                    <div className="footer__content--item">
                        <h3 className="footer__title">News</h3>
                        <ul className="footer__links">
                            <li><a href="#">Events</a></li>
                            {/* <li><a href="#Contact">Contact</a></li> */}
                            <li><NavLink to="/careers">Contact</NavLink></li>
                            {/* <li><a href="#">Legals</a></li> */}
                            <li><NavLink to="/">Legals</NavLink></li>
                        </ul>
                    </div>

                    <div className="footer__content--item">
                        <h3 className="footer__title">Social Links</h3>
                        <ul className="footer__links">
                            <li><a href="#">Facebook</a></li>
                            <li><a href="#">Twitter</a></li>
                            <li><a href="#">Instagram</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;