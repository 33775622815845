import React from "react";
import "./Home.css";
import Navbar from "../Navbar/Navbar";
import Project from "../Project/Project";
import WorkProcess from "../WorkProcess/WorkProcess";
import images from "../../constants/images";

const Home = () => {
    return (
        <section id="home">
        <div className="header" style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url(${images.header_bg}) center/cover no-repeat`
        }}>
            <div className="container">
                <div className="header__content text__center text__light flex flex__center">
                    <div className="header__content--left"></div>
                    <div className="header__content--right">
                        <h1 className="header__title fw__6">Global FirstWave</h1>
                        <p className="para__text">"Welcome to GLobal FirstWave, we appreciate your presence and interest towards our services.<br/>We value every individuals.</p>
                    </div>
                </div>
            </div>
        </div>
        <Project />
        <WorkProcess />
    </section>
    )
}

export default Home;