import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import images from "../../constants/images";

const Header = () => (
    // <section id="Header">
    //     <div className="header" style={{
    //         background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url(${images.header_bg}) center/cover no-repeat`
    //     }}>
            <Navbar />

    //         <div className="container">
    //             <div className="header__content text__center text__light flex flex__center">
    //                 <div className="header__content--left"></div>
    //                 <div className="header__content--right">
    //                     <h1 className="header__title fw__6">Global FirstWave</h1>
    //                     <p className="para__text">"Welcome to GLobal FirstWave, we appreciate your presence and interest towards our services.<br/>We value every individuals.</p>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </section>
)

export default Header;